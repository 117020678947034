import RoxBrowser from 'rox-browser'

const flags = {
  canSeeUiRebrand: new RoxBrowser.Flag(),
  canSeeAuctionPackage: new RoxBrowser.Flag(),
  canSeeAuctionPackageMenu: new RoxBrowser.Flag(),
  canSeeDemographics: new RoxBrowser.Flag(),
  canSeeAuctionPackagePrivateDealsTargetedImpressions: new RoxBrowser.Flag(),
  isSelfServePlanningEnabled: new RoxBrowser.Flag(),
  cityZoomBreakpoint: new RoxBrowser.Flag(),
  postalCodeZoomBreakpoint: new RoxBrowser.Flag()
}

export default flags
